import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input, AutoComplete, message, Upload } from "antd";
import { Col, Row } from "react-bootstrap";
import { IoMdSend } from "react-icons/io";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { MdOutlineContactMail } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { MdOutlinePreview } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineContacts } from "react-icons/md";
import { FaUserGroup } from "react-icons/fa6";

import ContactModal from "../components/contactModal";

import ExcelModal from "../components/excelModal";
import TemplateModal from "../components/templateModal";
import PreviewModal from "../components/previewModal";
import ContactItem from "../components/contactItem";

import Contacts from "../components/contact.json";
import { FILE_UPLOAD, POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import Groupmodal from "../components/groupModal";
import GroupItem from "../components/groupItem";

function Broadcast(props: any) {
  const User = useSelector((state: any) => state.User);
  const [loading, setLoading] = useState(false);
  const [messageCount, setMessageCount] = useState(0);

  const [excelModal, setExcelModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [groupesModal, setGroupesModal] = useState(false);
  const [number, setNumber] = useState<any>(null);
  const [contacts, setContacts] = useState<any[]>([]);
  const [groupData, setGroupData] = useState<any>([]);
  const [contactsOptions, setContactsOptions] = useState<any>([]);

  const [attachment, setAttachment] = useState<any>(null);

  const [form] = Form.useForm();
  const [templateModal, setTemplateModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const searchContact = (numb: any) => {
    try {
      setNumber(numb);
      var arr = Contacts;
      var filterData: any = arr.filter((item) => {
        return item?.Number === numb
          ? { label: item?.Name, value: item?.Number }
          : null;
      });
      if (filterData?.length) {
        filterData = filterData.map((item: any) => ({
          label: item?.Name,
          value: item?.Number,
        }));
        setContactsOptions(filterData);
      }
    } catch (err) {}
  };

  const addContact = (numb: any) => {
    try {
      if (numb?.length >= 10) {
        const checkExist = contacts?.findIndex((i: any) => i.phone === numb);
        if (checkExist >= 0) {
          message.error("Number already exist");
        } else {
          let obj = {
            Name: null,
            phone: numb,
            gender: null,
            type: User?.user?.companyType,
          };
          setContacts((prev) => [obj, ...prev]);
          setTimeout(() => {}, 10);
        }
      } else {
        message.error("sorry Number not valid");
      }
    } catch (err) {
      message.error("sorry Number not valid");
    }
  };

  const getTemplate = (templat: any) => {
    message.success("Template Selected Successfully.");
    form.setFieldsValue({
      message: templat?.[0]?.content,
    });
  };

  const getContacts = (contact: any[]) => {
    const duplicates = contacts?.filter((itemA) =>
      contact?.some((itemB) => itemB.phone == itemA.phone)
    );
    if (duplicates?.length > 0) {
      message.error("Phone Number has been already added");
    } else {
      setContactModal(false);
      setContacts((prev) => [...contact, ...prev]);
      message.success(`${contact?.length} Phone Numbers has been Selected`);
    }
  };

  const handleFileChange = async (info: any) => {
    console.log("info.fileinfo.fileinfo.file", info.file);
    if (info.file) {
      try {
        const uploadedUrl = await FILE_UPLOAD(info.file);
        setAttachment(uploadedUrl);
        message.success("File uploaded successfully");
      } catch (err) {
        message.error("File upload failed");
      }
    }
  };

  const handleSubmit = async (val: any) => {
    console.log("val .....", val);
    try {
      setLoading(true);
      const phoneNumbers = contacts.map(
        (contact) => `${contact.code ?? ""}${contact.phone}`
      );
      const groupPhoneNumbers: any = groupData
        .flatMap((group: any) =>
          group.contacts.map(
            (contact: any) => `${contact.code || ""}${contact.phone}`
          )
        )
        .filter(Boolean);
      let reqObj = {
        file_url: attachment,
        message: val.message,
        session: props?.data?.projectName,
        to: phoneNumbers?.length > 0 ? phoneNumbers : groupPhoneNumbers,
        batchSize: val.batchCount,
      };

      let broadcastRes: any = await POST(
        API.BROADCAST_V1,
        reqObj,
        props?.data?.token
      );

      if (broadcastRes.status) {
        message.success("Message broadcast initiated successfully");
        props.onBroadcastSent();
      } else {
        // message.error("Failed to broadcast message");
        message.error(broadcastRes?.message);
      }
    } catch (err) {
      console.log("err", err);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
      setContacts([]);
      setGroupData([]);
    }
  };

  const DeleteNumber = async (number: any) => {
    try {
      setContacts((prevContacts: any[]) =>
        prevContacts?.filter((contact) => contact?.phone !== number?.phone)
      );
    } catch (err) {
      console.log("Error deleting number:", err);
    }
  };
  const DeleteGroup = async (number: any) => {
    try {
      setGroupData((prevContacts: any[]) =>
        prevContacts?.filter((group) => group?.name !== number?.name)
      );
    } catch (err) {
      console.log("Error deleting number:", err);
    }
  };
  const handleGroupSelection = (selectedGroups: any[]) => {
    console.log("Selected groups in parent:", selectedGroups);
    setGroupData(selectedGroups);
    setGroupesModal(false);
  };
  return (
    <div style={{ paddingBottom: 20 }}>
      <div className="Projects-txt1">Broadcast Message</div>
      <div className="Projects-Broadcast">
        <Form form={form} onFinish={handleSubmit}>
          <Row>
            <Col sm={6} xs={12}>
              <div
                className="Projects-rowBox"
                style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 5 }}
              >
                <div className="Projects-Broadcasttxt1">Whatsapp Numbers </div>

                <div style={{ flex: 1 }} />
                <div
                  style={{ marginBottom: 10 }}
                  onClick={() => setExcelModal(true)}
                >
                  <SiMicrosoftexcel size={25} color={"#3468eb"} />
                </div>
              </div>

              <div className="Projects-contactList">
                <Row>
                  <Col sm={2} xs={4}>
                    <Button
                      size="large"
                      block
                      style={{ height: 45 }}
                      icon={
                        <MdOutlineContactMail size={25} color={"#3468eb"} />
                      }
                      onClick={() => setContactModal(true)}
                    ></Button>
                  </Col>
                  <Col sm={2} xs={4}>
                    <Button
                      size="large"
                      block
                      style={{ height: 45 }}
                      icon={<FaUserGroup size={25} color={"#3468eb"} />}
                      onClick={() => setGroupesModal(true)}
                    ></Button>
                  </Col>
                  <Col sm={8} xs={8}>
                    <Form.Item noStyle>
                      <AutoComplete
                        style={{ width: "100%" }}
                        size="large"
                        options={contactsOptions}
                        onChange={searchContact}
                        onSelect={addContact}
                      >
                        <Input
                          size="large"
                          type="number"
                          placeholder="91 1234567890"
                          suffix={
                            <IoMdAdd
                              size={30}
                              color="green"
                              onClick={() => addContact(number)}
                            />
                          }
                        />
                      </AutoComplete>
                    </Form.Item>
                  </Col>
                </Row>
                <div style={{ marginLeft: -15, marginRight: -15 }}>
                  <hr />
                  {contacts?.length ? (
                    <div>
                      {contacts.map((item: any) => {
                        return (
                          <ContactItem
                            close={(num: string | number) => DeleteNumber(num)}
                            item={item}
                          />
                        );
                      })}
                    </div>
                  ) : groupData?.length ? (
                    <div>
                      {groupData.map((item: any) => {
                        return (
                          <GroupItem
                            close={(num: string | number) => DeleteGroup(num)}
                            item={item}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className="Projects-emptyBox">
                      <MdOutlineContacts size={30} />
                      <div>No Numbers</div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col sm={6} xs={12}>
              <div className="Projects-rowBox">
                <div className="Projects-Broadcasttxt1">Message</div>
                <div style={{ flex: 1 }} />
                <div style={{ marginBottom: 15 }}>
                  <Button size="small" onClick={() => setTemplateModal(true)}>
                    Templates
                  </Button>
                </div>
              </div>
              <Form.Item
                name={"message"}
                rules={[{ required: true, message: "required" }]}
              >
                <Input.TextArea
                  size="large"
                  rows={6}
                  placeholder="Enter Message"
                />
              </Form.Item>
              <div className="Projects-Broadcasttxt1">Attachments</div>
              <Form.Item>
                <Upload.Dragger
                  name="file"
                  multiple={false}
                  customRequest={({ file }) => handleFileChange({ file })}
                  showUploadList={false}
                >
                  <p className="ant-upload-drag-icon">
                    <IoDocumentAttachOutline size={30} />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Upload.Dragger>
                {attachment && (
                  <div style={{ marginTop: 10 }}>
                    <span>File uploaded successfully</span>
                    <Button
                      type="link"
                      onClick={() => setAttachment(null)}
                      style={{ marginLeft: 10 }}
                    >
                      Remove
                    </Button>
                  </div>
                )}
              </Form.Item>

              <div className="Projects-Broadcasttxt1">Message Settings</div>
              <div className="Projects-FileItem">
                <div className="Projects-rowBox">
                  <div style={{ flex: 1 }}>
                    Wait 10 to 20 seconds after every
                  </div>
                  <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                    <Form.Item name={"batchCount"}>
                      <Input size="large" style={{ width: 70 }} />
                    </Form.Item>
                  </div>
                  <div>Messages</div>
                </div>
              </div>
              <br />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={6} xs={12}></Col>
            <Col sm={6} xs={12}>
              <Row>
                <Col sm={6} xs={12}>
                  <Button
                    block
                    style={{ height: 50 }}
                    icon={<MdOutlinePreview />}
                    onClick={() => setPreviewModal(true)}
                  >
                    Preview
                  </Button>
                </Col>
                <Col sm={6} xs={12}>
                  <Button
                    block
                    type="primary"
                    style={{ height: 50 }}
                    icon={<IoMdSend />}
                    htmlType="submit"
                    loading={loading}
                  >
                    Send Message
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
      {contactModal ? (
        <ContactModal
          open={contactModal}
          onCancel={() => setContactModal(false)}
          multi={true}
          getContacts={getContacts}
        />
      ) : null}
      {groupesModal ? (
        <Groupmodal
          open={groupesModal}
          onCancel={() => setGroupesModal(false)}
          multi={true}
          onSubmit={(data: any) => setGroupData(data)}
        />
      ) : null}
      {excelModal ? (
        <ExcelModal
          open={excelModal}
          onChange={(data: any) => setContacts(data)}
          onCancel={() => setExcelModal(false)}
        />
      ) : null}
      {templateModal ? (
        <TemplateModal
          open={templateModal}
          onCancel={() => setTemplateModal(false)}
          getTemplate={getTemplate}
        />
      ) : null}
      {previewModal ? (
        <PreviewModal
          open={previewModal}
          file={attachment}
          data={form.getFieldsValue()}
          onCancel={() => setPreviewModal(false)}
        />
      ) : null}
    </div>
  );
}

export default Broadcast;
