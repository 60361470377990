import { FaRegCircleUser } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

function GroupItem(props: any) {
  return (
    <div className="Projects-contactItem">
      <div style={{ flex: 1 }}>
        <div style={{ fontSize: 10 }}>
          {props?.item?.name ? props?.item?.name : "unknown"}
        </div>
        {props?.item?.contacts?.map((numbers: any) => {
          return (
            <div style={{ fontSize: 14 }}>
              +{numbers?.code} {numbers?.phone} - {numbers?.name}
            </div>
          );
        })}
      </div>
      <div>
        <IoClose
          onClick={() => props?.close(props?.item)}
          color="red"
          size={20}
        />
      </div>
    </div>
  );
}

export default GroupItem;
