import React, { useState } from "react";
import { Alert, Button, message, Modal } from "antd";
import * as XLSX from "xlsx";
import Dropzone from "react-dropzone";

const ExcelModal = (props: any) => {

  const handleDrop = async (acceptedFiles: any) => {
    try {
      // props?.setloading(true);
      const allowedTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      var myFile = acceptedFiles[0];
      console.log("myFile .....",myFile)
      // props?.setloading(true);
      if (!allowedTypes.includes(myFile.type)) {
        message.error("Only CSV or Excel files are allowed.");
        return;
      } else {
        const readedData: any = await readExcelFile(myFile);
        console.log("readedData .....",readedData)
        props?.onChange(readedData);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
    }
  };
 
  const readExcelFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        if (json) {
          resolve(json);
        } else {
          message.error(`Missing fields: Please refer to the sample Excel.`);
        }
      };
      reader.onerror = () => {
        message.error("Error reading file");
        reject("Error processing file");
      };
      reader.readAsArrayBuffer(file);
    });
  };



  return (
    <Modal
      title={<div className="Projects-txt1">Import Excel</div>}
      open={props?.open}
      onOk={()=>props?.onCancel()}
      onCancel={()=>props?.onCancel()}
      footer={false}
    >
      <div>
      <Dropzone
        onDrop={handleDrop}
        accept={{
          "text/csv": [".csv"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
          "application/vnd.ms-excel": [".xls"],
        }}
      >
        {({ getRootProps, getInputProps }: any) => (
          <section>
            <div
              {...getRootProps({
                className: "vvip-FilePicker",
              })}
            >
              <input {...getInputProps()} />
              <div>
                <div className="vvip-FilePicker2">
                  {props?.fileName ? (
                    <div>{props?.fileName}</div>
                  ) : (
                    <div>Upload CSV/Excel</div>
                  )}
                </div>
                <div className="vvip-FilePicker-txt2 ms-2 mt-2">
                  Allowed file types: .csv , .xlsx
                </div>
              </div>
                  <Button className="mb-2" size="large" type="primary" loading={props?.loading}>
                    {props?.file ? "Choose Another File" : "Choose File"}
                  </Button>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
    </Modal>
 
  );
};

export default ExcelModal;
