import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  List,
  Modal,
  Select,
  message,
} from "antd";
import API from "../../../config/api";
import { DELETE, GET, POST } from "../../../utils/apiCalls";
import LoadingBox from "../../../components/loading";
import { ExecuteFunction } from "../../../shared/helpers/executeFn";
import Country from "../../../config/countryCode.json";
import { useSelector } from "react-redux";

function removeByIndex(array: any[], indexToRemove: any): any[] {
  const index = array.findIndex((data) => data?.id === indexToRemove);
  if (index !== -1) {
    return [...array.slice(0, index), ...array.slice(index + 1)];
  }
  return array;
}

const GroupModal = (props: any) => {
  const [groups, setGroups] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<any>({});
  const [selected, setSelected] = useState<any[]>([]);
  const [expandedGroup, setExpandedGroup] = useState<any>(null);
  const [form] = Form.useForm();
  const user = useSelector((state: any) => state?.User);

  const fetchGroups = async (page: number = 1) => {
    const url = `${API.GROUP_LIST}?order=DESC&take=20&page=${page}`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setGroups(response?.data);
        setMeta(response?.meta);
      } else {
        message.error(response?.message || "Failed to fetch groups");
      }
    } catch (err) {
      message.error("Something went wrong while fetching groups");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleSubmit = () => {
    if (selected.length === 0) {
      message.warning("Please select at least one group");
      return;
    }
    props.onSubmit(selected);
    props.onCancel();
  };

  const handleContactSubmit = async (values: any, groupId: any) => {
    try {
      const code = values.code?.replace("+", "") || "91";
      const response: any = await POST(`${API.GROUPS}/${groupId}/contacts`, {
        name: values.name,
        phone: values.phone,
        code: code,
        type: "Technology",
        gender: "male",
      });

      if (response?.status) {
        fetchGroups();
        form.resetFields();
        message.success("Contact added successfully");
      } else {
        message.error(response?.message || "Failed to add contact");
      }
    } catch (error) {
      message.error("Failed to add contact");
    }
  };

  const handleRemoveContact = async (groupId: any, contact: any) => {
    try {
      const response: any = await DELETE(
        `${API.GROUPS}/${groupId}/contacts/${contact.id}`
      );
      console.log("response ..handleContactSubmit..", response);

      if (response?.status) {
        fetchGroups(); // Refresh the groups list
        message.success("Contact removed successfully");
      } else {
        message.error(response?.message || "Failed to remove contact");
      }
    } catch (error) {
      message.error("Failed to remove contact");
    }
  };

  const handleGroupSelection = (item: any) => {
    setSelected((prevSelected) => {
      if (prevSelected.some((data) => data.id === item.id)) {
        return removeByIndex(prevSelected, item.id);
      }

      if (props.multi) {
        return [...prevSelected, item];
      }

      return [item];
    });
  };
  const Selector = (
    <Form.Item name="code" noStyle rules={[{ required: true, message: "" }]}>
      <Select
        style={{ width: 85 }}
        placeholder={"Code"}
        size="large"
        showSearch={true}
      >
        {Country.map((item: any) => {
          return (
            <Select.Option key={item.dial_code} value={item.dial_code}>
              {item.dial_code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
  const renderGroupItem = (item: any) => {
    console.log("item...", item);
    return (
      <>
        <div
          className={`col-5 ${item.name === "Group Name" ? "fw-bold" : ""}`}
          onClick={() =>
            item.id !== "header" &&
            setExpandedGroup(expandedGroup === item.id ? null : item.id)
          }
        >
          {item.name}
        </div>
        {item.id !== "header" && (
          <div className="col-1">
            <Checkbox
              onChange={() => handleGroupSelection(item)}
              checked={selected.some((data) => data.id === item.id)}
            />
          </div>
        )}
      </>
    );
  };

  const renderContactForm = (item: any) => {
    return (
      <div className="w-full mt-4">
        <Form
          form={form}
          onFinish={(values) => handleContactSubmit(values, item.id)}
          layout="vertical"
          className="mb-4"
        >
          <Form.Item
            name={"name"}
            rules={[{ required: true, message: "required" }]}
          >
            <Input
              // size="large"
              placeholder="Name"
            />
          </Form.Item>
          <Form.Item
            name={"phone"}
            rules={[{ required: true, message: "required" }]}
          >
            <Input
              addonBefore={Selector}
              type="number"
              placeholder="Enter Phone Number"
            />
          </Form.Item>
          <div className="d-flex gap-2">
            <Button onClick={() => setExpandedGroup(null)}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </div>
        </Form>

        <List
          size="small"
          dataSource={item.contacts}
          renderItem={(contact: any) => (
            <List.Item
              actions={[
                <Button
                  key="remove"
                  danger
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveContact(item.id, contact);
                  }}
                >
                  Remove
                </Button>,
              ]}
            >
              {/* <List.Item.Meta
                title={contact.name}
                description={`+${contact.code} ${contact.phone}`}
              /> */}
              <List.Item.Meta
                title={<span className="text-sm">{contact.name}</span>}
                description={
                  <span className="text-xs mb-0">{`+${contact.code} ${contact.phone}`}</span>
                }
                className="mb-0"
              />
            </List.Item>
          )}
        />
      </div>
    );
  };

  return (
    <Modal
      title={
        <div className="Projects-txt1 ms-0">
          {`Groups (${meta?.total_count ?? 0})`}
        </div>
      }
      open={props.open}
      onCancel={props.onCancel}
      footer={false}
    >
      <Alert
        type="warning"
        description={
          <div style={{ fontSize: 12 }}>
            Click on the group name to add contacts to group
          </div>
        }
      />
      <div
        className="custom-scrollbar"
        style={{ height: "40vh", overflowY: "auto" }}
      >
        {loading ? (
          <LoadingBox />
        ) : (
          <List
            itemLayout="vertical"
            dataSource={[{ name: "Group Name", id: "header" }, ...groups]}
            renderItem={(item: any) => (
              <List.Item className="flex flex-col">
                <div className="d-flex items-center justify-content-between w-full">
                  {renderGroupItem(item)}
                </div>
                {expandedGroup === item.id && renderContactForm(item)}
              </List.Item>
            )}
          />
        )}
      </div>
      <div className="d-flex gap-2 justify-content-end mt-4">
        <Button onClick={() => ExecuteFunction(props.onCancel)}>Cancel</Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={loading || selected.length === 0}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default GroupModal;
