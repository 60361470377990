import React, {useState } from "react";
import { Modal, Select, message } from "antd";
import { Button, Form, Input } from "antd";
import Country from "../../config/countryCode.json";
import { POST, PUT } from "../../utils/apiCalls";
import API from "../../config/api";
import { useSelector } from "react-redux";
const FormModal = (props: any) => {
  const [form] = Form.useForm();
  const User = useSelector( (state: any) => state?.User);
  const [loading2, setLoading2] = useState(false);
  const { Option } = Select;

  const modalClose = () => {
    props?.close();
    form.resetFields();
  };

  const formSubmitHandler = async (values: any) => {
    const obj = {
      ...values,
      companyId:User?.user?.id,
      type: User?.user?.companyType,
    };
    try {
      setLoading2(true);
      const response: any =
      props?.data?.id
          ? await PUT(API.CONTACTS_EDIT + props?.data?.id, obj)
          : await POST(API.CONTACTS_ADD, obj);
      if (response?.status) {
        message.success( `Contact ${props?.data?.id? "Added" : "Updated"} Successfully.`);
        props?.onChange();
        modalClose();
      } else {
        message.error(response?.message);
      }
    } catch (err) {
      message.error("Something went wrong...");
    } finally {
      setLoading2(false);
    }
  };

  const Selector = (
    <Form.Item name="code" noStyle rules={[{ required: true }]}>
      <Select style={{ width: 85 }} placeholder={"Code"} showSearch={true}>
        {Country?.map((item: any) => {
          return (
            <Select.Option key={item.dial_code} value={item.dial_code}>
              {item.dial_code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
  return (
    <Modal
      title={<div style={{fontSize:25}}>{`${props?.data?.id ? "Update" : "Add "} Contact`}</div>}
      open={props?.open}
      okText="Update"
      centered
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => modalClose()}
    >
      <Form
        form={form}
        style={{ maxWidth: 600 }}
        layout="vertical"
        onFinish={formSubmitHandler}
        initialValues={{
          name: props?.data?.name,
          phone: props?.data?.phone,
          gender: props?.data?.gender,
          code: props?.data?.code,
        }}
      >
        <Form.Item
          label="Name"
          name={"name"}
          rules={[
            {
              required: true,
              message: "Please Enter Name",
            },
            { min: 4, message: "Please enter a Valid name" },
          ]}
        >
          <Input placeholder="Name"  size="large"/>
        </Form.Item>
        <Form.Item
          name={"phone"}
          rules={[
            { required: true, message: "required" },
            { min: 8, message: "Phone number should Contain atleast 8 digits" },
          ]}
          label={"Phone Number"}
        >
          <Input
            addonBefore={Selector}
            style={{ width: "100%" }}
            placeholder="Enter Mobile No."
            type="number"
            size="large"
          />
        </Form.Item>
        {/* <Form.Item
          label="Gender"
          name="gender"
          rules={[
            {
              required: true,
              message: "Please Select Gender",
            },
          ]}
        >
          <Select
            bordered={false}
            style={{ width: "100%" }}
            className="border rounded"
            allowClear
            defaultValue={"Select Gender"}
            size="large"
          >
            <Option key={1} value={"male"}>
              {"Male"}
            </Option>
            <Option key={2} value={"female"}>
              {"Female"}
            </Option>
          </Select>
        </Form.Item> */}
        <div className="d-flex gap-2 justify-content-end">
          <Button
            onClick={() => modalClose()}
            size="large"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            loading={loading2}
            onClick={() => form.submit()}
            size="large"
          >
            {props?.data?.id ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default FormModal;
