import { Tag } from "antd";
import Icon from "../../../assets/images/message.png";
import { useNavigate } from "react-router-dom";
function ProjectCard(props: any) {
  const navigation = useNavigate();
  return (
    <div
      className="User-ProjectCard"
      onClick={() => navigation(`/auth/project/${props?.item?.id}`)}
    >
      <div className="User-ProjectCardBox">
        <div>
          <img src={Icon} style={{ width: 40 }} />
        </div>
        <div>
          <Tag color={props?.item?.isVerified ? "green" : "red"}>
            {props?.item?.isVerified ? "Verified" : "Unverifyied"}
          </Tag>
        </div>
      </div>
      <div className="User-ProjectCardtxt1">{props?.item?.projectName}</div>
      <div className="User-ProjectCardtxt2">API ENABLED</div>
      <div className="User-ProjectCardtxt3">
        <div>
          Total <strong>{props?.item?.usedMessages}</strong> used
        </div>
      </div>
      <div className="User-ProjectCardtxt3">
        <div>
          Remaininig{" "}
          <strong>
            {" "}
            {props?.item?.MessagesLimit - props?.item?.usedMessages}
          </strong>{" "}
          Messages
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
