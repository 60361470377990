import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  List,
  Modal,
  Select,
  message,
} from "antd";
import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import LoadingBox from "../../../components/loading";
import { ExecuteFunction } from "../../../shared/helpers/executeFn";
import Country from "../../../config/countryCode.json";
import { useSelector } from "react-redux";

function removeByIndex(array: any[], indexToRemove: number) {
  const index = array.findIndex((data) => data?.id == indexToRemove);
  if (index !== -1) {
    return array?.slice(0, index)?.concat(array?.slice(index + 1));
  }
  return array;
}
const ContactModal = (props: any) => {
  const [contacts, setContacts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<any>({});
  const [selected, setSelected] = useState<any[]>([]);
  const [addForm, setAddForm] = useState(false);
  const [form] = Form.useForm();
  const User = useSelector((state: any) => state?.User);
  console.log("User ...", User);

  const fetchContacts = async (pag: number = 1) => {
    const url = API.CONTACTS_LIST + `?order=DESC&take=20&page=1`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setContacts(response?.data);
        setMeta(response?.meta);
      } else {
        message.error(response?.message);
      }
    } catch (err) {
      message.error("Something went wrong..");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchContacts();
  }, []);
  // const onFinish = async (val: any) => {
  //   console.log("val ...", val);
  //   setLoading(true);
  //   try {
  //     let api = API.CONTACTS_ADD;
  //     const obj = {
  //       name:val?.name,
  //       phone: val?.phone,
  //       code: val?.code,
  //       // countryCode: val?.countryCode?.trim().replace(/"/g, '') ,
  //       companyId: User?.user?.id,
  //       type: User?.user?.companyType,
  //       gender: "male",
  //     };
  //     console.log("obj ....", obj);
  //     const res: any = await POST(api, obj);
  //     console.log("res ....", res);
  //     if (res?.status) {
  //       setContacts(res?.data);
  //       setMeta(res?.meta);
  //       setLoading(false);
  //       fetchContacts();
  //       setAddForm(false);
  //     } else {
  //       message.error(res?.message);
  //       setLoading(false);
  //       fetchContacts();
  //       // setAddForm(false)
  //     }
  //   } catch (err) {
  //     console.log("errrrr", err);
  //     setLoading(false);
  //     fetchContacts();
  //   }
  // };
  const onFinish = async (val: any) => {
    console.log("val ...", val);
    setLoading(true);
    try {
      let api = API.CONTACTS_ADD;
      const obj = {
        name: val?.name,
        phone: val?.phone,
        code: val?.code,
        companyId: User?.user?.id,
        type: User?.user?.companyType,
        gender: "male",
      };
      console.log("obj ....", obj);
      const res: any = await POST(api, obj);
      console.log("res ....", res);
      if (res?.status) {
        // Ensure contacts is updated with the new array
        setContacts((prev) => Array.isArray(prev) ? [...prev, res.data] : [res.data]);
        setMeta(res?.meta);
        fetchContacts(); // Optionally re-fetch contacts to refresh the list
        setAddForm(false);
      } else {
        message.error(res?.message);
      }
    } catch (err) {
      console.log("errrrr", err);
      message.error("Something went wrong..");
    } finally {
      setLoading(false);
    }
  };
  
  const Selector = (
    <Form.Item name="code" noStyle rules={[{ required: true, message: "" }]}>
      <Select
        style={{ width: 85 }}
        placeholder={"Code"}
        size="large"
        showSearch={true}
      >
        {Country.map((item: any) => {
          return (
            <Select.Option key={item.dial_code} value={item.dial_code}>
              {item.dial_code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
  return (
    <Modal
      title={
        <div className="Projects-txt1 ms-0">{`Contacts (${
          meta?.total_count ?? 0
        })`}</div>
      }
      open={props?.open}
      onOk={() => props?.onCancel()}
      onCancel={() => props?.onCancel()}
      footer={false}
    >
      <div
        style={{ height: "40vh", overflowY: "scroll", scrollbarWidth: "none" }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={() => setAddForm(true)}>Add +</Button>
        </div>
        {addForm && (
          <Form form={form} onFinish={onFinish} className="mt-2">
            <Form.Item
              rules={[{ required: true, message: "Enter User Name" }]}
              name={"name"}
              className="mb-2"
            >
              <Input size="large" placeholder="Name" />
            </Form.Item>
            <Form.Item
              name={"phone"}
              rules={[{ required: true, message: "required" }]}
              className="mb-2"
            >
              <Input
                addonBefore={Selector}
                size="large"
                type="number"
                placeholder="Enter Phone Number"
              />
            </Form.Item>
            <Button
              loading={loading}
              style={{ width: "100%" }}
              size="middle"
              htmlType="submit"
              type="primary"
              className="mb-2"
            >
              Submit
            </Button>
          </Form>
        )}
        {loading ? (
          <LoadingBox />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={[{ name: "Name", phone: "Phone" }, ...contacts]}
            renderItem={(item, index) => (
              <List.Item
                className={`contacts-list ${
                  item.name == "Name" ? "underline" : ""
                }`}
              >
                <div
                  className={`col-5 ${item?.name == "Name" ? "fw-bold" : ""}`}
                >
                  {item?.name}
                </div>
                <div
                  className={`col-6 ${item?.phone == "Phone" ? "fw-bold" : ""}`}
                >
                  {item?.code}
                  {item?.phone}
                </div>
                {item?.name == "Name" ? null : (
                  <div className="col-1">
                    <Checkbox
                      onChange={(val) => {
                        let array = [];
                        if (selected?.some((data) => data?.id == item?.id)) {
                          array = removeByIndex(selected, item?.id);
                        } else {
                          if (props?.multi === true) {
                            array = [...selected, { ...item }];
                          } else {
                            array = [{ ...item }];
                          }
                        }
                        setSelected(array);
                      }}
                      checked={
                        selected?.some((data) => data?.id == item?.id)
                          ? true
                          : false
                      }
                      defaultChecked={false}
                    ></Checkbox>
                  </div>
                )}
              </List.Item>
            )}
          />
        )}
      </div>
      <div className="d-flex gap-2 justify-content-end">
        <Button onClick={() => ExecuteFunction(props?.onCancel)}>Cancel</Button>
        <Button
          type="primary"
          onClick={() => {
            if (selected?.length) {
              ExecuteFunction(props?.getContacts, selected);
              // ExecuteFunction(props?.onCancel);
            } else {
              message.error("No contact has been Selected.");
            }
          }}
        >
          {`Select Contact${props?.multi == true ? "s" : ""}`}
        </Button>
      </div>
    </Modal>
  );
};

export default ContactModal;
