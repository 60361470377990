import "./styles.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import LoadingBox from "../../components/loading";
import { MdContacts } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { IoTicketSharp } from "react-icons/io5";
import { RiGroupFill } from "react-icons/ri";

import CreateCard from "./components/createCard";
import ProjectCard from "./components/projectCard";
import MenuCard from "./components/menuCard";
import { POST } from "../../utils/apiCalls";
import API from "../../config/api";
import { Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { HiTemplate } from "react-icons/hi";
import Groupmodal from "../projects/components/groupModal";
import Groupcontactmodal from "../projects/components/groupcontactModal";

function Dashboard() {
  const User = useSelector((state: any) => state.User);
  const [loading, setLoading] = useState(true);
  const [groupescontactModal, setGroupescontactModal] = useState(false);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    getStaff();
  }, [page, take]);

  const getStaff = async () => {
    try {
      setLoading(true);
      let obj = {
        companyId: User?.user?.id,
        page: page,
        take: take,
      };
      let api = API.PROJECT_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        console.log(response?.meta);
        setData(response?.data);
        setMeta(response?.meta);
      } else {
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className="User-Dashboard">
      <br />
      <Row>
        <Col sm={2} xs={12} onClick={() => navigate("coastUsage")}>
          <MenuCard title={"Cost and usage"} icon={<MdPayments />} />
        </Col>
        <Col sm={2} xs={12} onClick={() => navigate("payments")}>
          <MenuCard title={"Payments"} icon={<IoTicketSharp />} />
        </Col>
        <Col sm={2} xs={12} onClick={() => navigate("contacts")}>
          <MenuCard title={"Contacts"} icon={<MdContacts />} />
        </Col>
        <Col sm={2} xs={12} onClick={() => navigate("templates")}>
          <MenuCard title={"Templates"} icon={<HiTemplate />} />
        </Col>
        <Col             onClick={() => setGroupescontactModal(true)}

          sm={2}
          xs={12}
          //  onClick={() => navigate("templates")}
        >
          <MenuCard
            onClick={() => setGroupescontactModal(true)}
            title={"Groups"}
            icon={<RiGroupFill />}
          />
        </Col>
      </Row>
      <br />
      <br />
      <div className="User-Dashboard-row">
        <div className="User-Dashboardtxt1">My Projects</div>
        <div style={{ flex: 1 }} />
        <div>
          <Form>
            <Form.Item noStyle>
              <Input style={{ width: 200 }} placeholder="Search Projects" />
            </Form.Item>
          </Form>
        </div>
      </div>
      {loading ? (
        <LoadingBox />
      ) : (
        <Row>
          {meta?.total_count < 3 && (
            <Col sm={3} xs={12}>
              <CreateCard />
            </Col>
          )}
          {data?.map((item: any) => {
            return (
              <Col sm={3} xs={12} key={item?.id}>
                <ProjectCard item={item} />
              </Col>
            );
          })}
        </Row>
      )}
      {groupescontactModal ? (
        <Groupcontactmodal
          open={groupescontactModal}
          onCancel={() => setGroupescontactModal(false)}
          multi={true}
          // getContacts={getContacts}
        />
      ) : null}{" "}
    </div>
  );
}

export default Dashboard;
