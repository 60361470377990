import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  List,
  Modal,
  Select,
  message,
} from "antd";
import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import LoadingBox from "../../../components/loading";
import { ExecuteFunction } from "../../../shared/helpers/executeFn";
import Country from "../../../config/countryCode.json";
import { useSelector } from "react-redux";

function removeByIndex(array: any[], indexToRemove: number) {
  const index = array.findIndex((data) => data?.id == indexToRemove);
  if (index !== -1) {
    return array?.slice(0, index)?.concat(array?.slice(index + 1));
  }
  return array;
}

const Groupcontactmodal = (props: any) => {
  const [groupes, setGroupes] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<any>({});
  const [selected, setSelected] = useState<any[]>([]);
  const [addForm, setAddForm] = useState(false);
  const [form] = Form.useForm();
  const User = useSelector((state: any) => state?.User);

  const fetchContacts = async (pag: number = 1) => {
    const url = API.CONTACTS_LIST + `?order=DESC&take=20&page=1`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setGroupes(response?.data);
        setMeta(response?.meta);
      } else {
        message.error(response?.message);
      }
    } catch (err) {
      message.error("Something went wrong..");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const onFinish = async (val: any) => {
    try {
      setLoading(true);
      let api = API.GROUP_CREATE;
      const obj = {
        name: val?.groupName,
        contactId: selected.map((contact) => contact.id),
      };

      let res: any = await POST(api, obj);

      if (res?.status) {
        message.success("Group created successfully");
        form.resetFields();
        setSelected([]);
        fetchContacts();
        props?.onCancel();
      } else {
        message.error(res?.message || "Failed to create group");
      }
    } catch (err) {
      console.error("Error creating group:", err);
      message.error("Something went wrong while creating the group");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <div className="Projects-txt1 ms-0">{`Groupes (${
          meta?.total_count ?? 0
        })`}</div>
      }
      open={props?.open}
      onOk={() => props?.onCancel()}
      onCancel={() => props?.onCancel()}
      footer={false}
    >
      <div style={{ display: "flex", flexDirection: "column", height: "60vh" }}>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            rules={[{ required: true, message: "Enter User Name" }]}
            name={"groupName"}
            className="mb-2"
          >
            <Input size="large" placeholder="Name" />
          </Form.Item>
        </Form>

        <div
          style={{
            flex: 1,
            overflowY: "auto",
          }}
        >
          {loading ? (
            <LoadingBox />
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={[{ name: "Name", phone: "Phone" }, ...groupes]}
              renderItem={(item, index) => (
                <List.Item
                  className={`contacts-list ${
                    item.name == "Name" ? "underline" : ""
                  }`}
                >
                  <div
                    className={`col-5 ${item?.name == "Name" ? "fw-bold" : ""}`}
                  >
                    {item?.name}
                  </div>
                  <div
                    className={`col-6 ${
                      item?.phone == "Phone" ? "fw-bold" : ""
                    }`}
                  >
                    {item?.code}
                    {item?.phone}
                  </div>
                  {item?.name == "Name" ? null : (
                    <div className="col-1">
                      <Checkbox
                        onChange={(val) => {
                          let array = [];
                          if (selected?.some((data) => data?.id == item?.id)) {
                            array = removeByIndex(selected, item?.id);
                          } else {
                            if (props?.multi === true) {
                              array = [...selected, { ...item }];
                            } else {
                              array = [{ ...item }];
                            }
                          }
                          setSelected(array);
                        }}
                        checked={selected?.some((data) => data?.id == item?.id)}
                        defaultChecked={false}
                      />
                    </div>
                  )}
                </List.Item>
              )}
            />
          )}
        </div>

        <div className="d-flex gap-2 justify-content-end mt-3">
          <Button onClick={() => ExecuteFunction(props?.onCancel)}>
            Cancel
          </Button>
          <Button
            loading={loading}
            size="middle"
            htmlType="submit"
            type="primary"
            onClick={() => form.submit()}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Groupcontactmodal;
