import { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";
import PageHeader from "../route/pageHeader";
import LoadingBox from "../../components/loading";

import { Alert, Popconfirm, Tag, message } from "antd";
import { AiOutlineMessage } from "react-icons/ai";
import { MdBroadcastOnHome } from "react-icons/md";
import { MdManageHistory } from "react-icons/md";
import { AiOutlineApi } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { useParams, useNavigate } from "react-router-dom";
import { PiChatsCircle } from "react-icons/pi";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";

import { DELETE, GET } from "../../utils/apiCalls";
import API from "../../config/api";

import Message from "./screens/message";
import Broadcast from "./screens/broadcast";
import History from "./screens/history";
import Apis from "./screens/apis";

function Projects() {
  const params = useParams();
  const navigate = useNavigate();
  const [screen, setScreen] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      const api = API.PROJECT_DETAILS + params?.id;
      let response: any = await GET(api, null);
      console.log("anshab --->", response);
      if (response?.status) {
        setData(response?.data);
        setLoading(false);
      } else {
        message.error(response.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err === >", err);
      setLoading(false);
    }
  };

  const getSession = async () => {
    try {
      const api = API.SESSION_CHECK + params?.id;
      let response: any = await GET(api, null);
      console.log("responseresponseresponseresponse --->", response);
      if (response?.status) {
        setData(response?.data);
        setLoading(false);
      } else {
        message.error(response.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err === >", err);
      setLoading(false);
    }
  };

  const deleteProject = async (id: any) => {
    try {
      let api = API.PROJECT_DELETE + id;
      let Delete: any = await DELETE(api);
      if (Delete?.status) {
        message.success("Project Deleted Successfully");
        navigate(-1);
      } else {
        message.error("oops.something went wrong");
      }
    } catch (err) {
      message.error("oops.something went wrong");
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <PageHeader
            icon={<PiChatsCircle />}
            title={data?.projectName}
            desc={data?.countryCode + data?.hostPhoneNumber}
            back={true}
          >
            <Tag color={data?.isVerified ? "green" : "red"}>
              <MdOutlineVerifiedUser />{" "}
              {data?.isVerified ? "Phone Verified" : "Phone Unverifyied"}
            </Tag>
            <Tag className="Projects-Box">
              <FaRegCircleCheck color="green" /> Total Messages Send -{" "}
              <span className="Projects-Boxtxt1">{data?.usedMessages}</span>
            </Tag>
            <Tag color="blue" className="Projects-Box">
              <FaRegCircleCheck color="green" /> Remaining Messages -{" "}
              <span className="Projects-Boxtxt1">
                {data?.MessagesLimit - data?.usedMessages}
              </span>
            </Tag>
          </PageHeader>
          <Row>
            <Col sm={3}>
              <div className="Projects-sideBar">
                <div
                  className={
                    screen === 1
                      ? "Projects-sideBarItem active"
                      : "Projects-sideBarItem"
                  }
                  onClick={() => setScreen(1)}
                >
                  <div className="Projects-sideBarItem1 blue">
                    <AiOutlineMessage size={20} />
                  </div>
                  <div>Send Message</div>
                </div>
                <div
                  className={
                    screen === 2
                      ? "Projects-sideBarItem active"
                      : "Projects-sideBarItem"
                  }
                  onClick={() => setScreen(2)}
                >
                  <div className="Projects-sideBarItem1 rose">
                    <MdBroadcastOnHome size={20} />
                  </div>
                  <div>Broadcast</div>
                </div>
                <div
                  className={
                    screen === 3
                      ? "Projects-sideBarItem active"
                      : "Projects-sideBarItem"
                  }
                  onClick={() => setScreen(3)}
                >
                  <div className="Projects-sideBarItem1 green">
                    <MdManageHistory size={20} />
                  </div>
                  <div>History</div>
                </div>
                <div
                  className={
                    screen === 4
                      ? "Projects-sideBarItem active"
                      : "Projects-sideBarItem"
                  }
                  onClick={() => setScreen(4)}
                >
                  <div className="Projects-sideBarItem1 orange">
                    <AiOutlineApi size={20} />
                  </div>
                  <div>API Keys</div>
                </div>
                <Popconfirm
                  title="Delete the Project"
                  description="Are you sure to delete this Project?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => deleteProject(params?.id)}
                >
                  <div
                    className="Projects-sideBarItem"
                    style={{ color: "red" }}
                  >
                    <div className="Projects-sideBarItem1 red">
                      <AiOutlineDelete size={20} />
                    </div>
                    <div>Delete Project</div>
                  </div>
                </Popconfirm>
                <br />
                <Alert
                  type="warning"
                  message={"Check Device Status"}
                  description={
                    <div style={{ fontSize: 12 }}>
                      In WhatsApp Web/Desktop, click on the three dots (menu) in
                      the top left corner and select Settings. Here, you'll see
                      information about the device currently connected,
                      including the browser or application used, the operating
                      system, and the last active time. Ensure that this
                      information matches your device and that it shows recent
                      activity.
                    </div>
                  }
                />
              </div>
            </Col>
            <Col sm={9}>
              <div className="Projects-mainBox">
                {screen === 1 ? (
                  <Message data={data} onMessageSent={getDetails} />
                ) : null}
                {screen === 2 ? (
                  <Broadcast data={data} onBroadcastSent={getDetails} />
                ) : null}
                {screen === 3 ? <History data={data} /> : null}
                {screen === 4 ? <Apis data={data} /> : null}
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default Projects;
